import React from "react";
import { Link } from "react-router-dom";

import { PrimaryPinkButton } from "../button/primary-pink-button";
import "./header.css";

const contactUsFeature = process.env.REACT_APP_CONTACT_US_FEATURE === "true";

const Header = () => {
  return (
    <div data-role="Header" className="header-header">
      <nav className="header-nav">
        <div className="header-container">
          <Link to="/" className="header-navlink Large">
            SeventhLab
          </Link>
          {/*
            Temporary disbling this until we can invest in the "About us" page

            <div className="header-menu">
            <Link to="/" className="header-navlink1 Large">
              Home
            </Link>
            <Link to="/about" className="header-navlink2 Large">
              About us
            </Link>
          </div> */}
          <div className="header-container1">
            <div className="header-container2">
              {contactUsFeature ? (
                <PrimaryPinkButton title="contact us" link="/contact-us" />
              ) : (
                <></>
              )}
            </div>
            <div data-role="BurgerMenu" className="header-burger-menu">
              <svg viewBox="0 0 1024 1024" className="header-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>
      <div data-role="MobileMenu" className="header-mobile-menu">
        <div className="header-top">
          <Link to="/" className="header-navlink4 Large">
            SeventhLab
          </Link>
          <div data-role="CloseMobileMenu" className="header-close-menu">
            <svg viewBox="0 0 1024 1024" className="header-icon2">
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
        </div>
        {/*
          Temporary disbling this until we can invest in the "About us" page

          <div className="header-mid">
          <div className="header-menu1">
            <Link to="/" className="header-navlink5 Large">
              Home
            </Link>
            <Link to="/about" className="header-navlink6 Large">
            About us
            </Link>
          </div>
        </div> */}
        <div className="header-bot">
          {contactUsFeature ? (
            <PrimaryPinkButton title="contact us" link="/contact-us" />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
