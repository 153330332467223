import React from "react";
import { Link } from "react-router-dom";

import "./primary-pink-button.css";

interface PrimaryPinkButtonProps {
  title: string;
  link?: string;
  submit?: boolean;
}

const PrimaryPinkButton = (props: PrimaryPinkButtonProps) => {
  const inputSubmit = props.submit ?? props.submit;
  const type = inputSubmit ? "submit" : undefined;

  return (
    <div className="primary-pink-button-container">
      <button
        className="primary-pink-button-button button ButtonSmall"
        type={type}
      >
        {props.link != undefined ? (
          <Link to={props.link}>{props.title}</Link>
        ) : (
          props.title
        )}
      </button>
    </div>
  );
};

export { PrimaryPinkButton };
