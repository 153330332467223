import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, ToastOptions, Bounce, toast } from "react-toastify";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import "react-toastify/dist/ReactToastify.css";

import { Header } from "../components/layout/header";
import { Footer } from "../components/layout/footer";
import { PrimaryPinkButton } from "../components/button/primary-pink-button";

import "./ContactUs.css";

const apiUrl = process.env.REACT_APP_CONTACT_API_URL ?? "";
const apiKey = process.env.REACT_APP_CONTACT_API_API_KEY ?? "";
const recaptchaClientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";

const ContactUsView = () => {
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const toastProperties: ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    data["token"] = token;

    axios
      .post(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      })
      .then((response) => {
        console.log(response);
        toast.success("Request sent successfully!", toastProperties);
        form.reset();
      })
      .catch((error) => {
        console.log(error);
        setRefreshReCaptcha(!refreshReCaptcha);
        toast.error(
          "Failed to send request. Please try again later.",
          toastProperties,
        );
      });
  };

  const setTokenFunc = (token: string) => {
    setToken(token);
  };

  return (
    <div className="contact-us-container">
      <Header />
      <ToastContainer {...toastProperties} />
      <div className="contact-us-hero">
        <div className="contact-us-container01">
          <div className="contact-us-card">
            <h1 className="contact-us-text HeadingOne">Contact us</h1>
            <span className="contact-us-text02 Lead">
              <p>
                Use the following form to send us any questions or requests you
                might have about our services.
              </p>
              <br />
              <p>
                We will get back to you as soon as possible. Thank you for
                considering SeventhLab.
              </p>
            </span>
            <hr />
            <form
              className="contact-us-form"
              onSubmit={handleSubmit}
              method="POST"
            >
              <label htmlFor="name" className="contact-us-label">
                Name
              </label>
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Name"
                required
                className="contact-us-input"
              />
              <label htmlFor="subject" className="contact-us-label">
                Subject
              </label>
              <input
                id="subject"
                type="text"
                name="subject"
                placeholder="Subject"
                required
                className="contact-us-input"
              />
              <label htmlFor="email" className="contact-us-label">
                Contact email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Contact email"
                required
                className="contact-us-input"
              />
              <label htmlFor="message" className="contact-us-label">
                Message{" "}
                <span className="contact-us-label02">(max 500 characters)</span>
              </label>
              <textarea
                id="message"
                name="message"
                maxLength={500}
                placeholder="Message"
                required
                className="contact-us-input"
              />
              <div className="contact-us-container02">
                <div className="contact-us-container03">
                  <PrimaryPinkButton title="Send" />
                  <GoogleReCaptchaProvider reCaptchaKey={recaptchaClientKey}>
                    <GoogleReCaptcha
                      onVerify={setTokenFunc}
                      refreshReCaptcha={refreshReCaptcha}
                      action="contactUs"
                    />
                  </GoogleReCaptchaProvider>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <img
        alt="Contact us first section background with multicolor waves"
        src="/img/home-heading-background.jpg"
        className="contact-us-heading-background"
      />
      <Footer></Footer>
    </div>
  );
};

export { ContactUsView };
