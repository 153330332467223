import React from "react";

import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";

import { HomeView } from "./views/Home";
import { ContactUsView } from "./views/ContactUs";

import "./App.css";

const contactUsFeature = process.env.REACT_APP_CONTACT_US_FEATURE === "true";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeView />} />
        {contactUsFeature ? (
          <Route path="/contact-us" element={<ContactUsView />} />
        ) : (
          <></>
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
