import React from "react";
import { Link } from "react-router-dom";

import "./footer.css";

const contactUsFeature = process.env.REACT_APP_CONTACT_US_FEATURE === "true";

const Footer = () => {
  return (
    <footer className="footer-footer">
      <div className="footer-container">
        <div className="footer-container1">
          <span className="footer-text">Seventh Lab</span>
          <span>Copyright &#169; Seventhlab Consulting S.L. 2024</span>
        </div>
        <div className="footer-container2">
          {contactUsFeature ? (
            <div className="footer-container3">
              <span className="footer-title Large">Company</span>
              {/* <span className="footer-link-name Large">About Us</span> */}
              <span className="footer-link-name Large">
                <Link to={"/contact-us"}>Contact Us</Link>
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="footer-container4">
            <span className="footer-title Large">Links</span>
            <span className="footer-link-name Large">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/seventh_lab"
              >
                Twitter
              </a>
            </span>
            <span className="footer-link-name Large">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/seventh-lab"
              >
                LinkedIn
              </a>
            </span>
          </div>
        </div>
      </div>
      <img
        alt="Footer background with white waves"
        src="/img/waves-white.svg"
        className="footer-image"
      />
    </footer>
  );
};

export { Footer };
