import React from "react";

import { Header } from "../components/layout/header";
import { Footer } from "../components/layout/footer";
import { FeatureCard } from "../components/content/feature-card";
// import { PrimaryPinkButton } from "../components/button/primary-pink-button";

import "./Home.css";

const HomeView = () => {
  return (
    <div className="home-container">
      <Header />
      <div className="home-hero">
        <div className="home-container01">
          <div className="home-card">
            <img
              alt="SeventhLab logo"
              src="/img/cloud.svg"
              className="home-logo"
            />
            <h1 className="home-text HeadingOne">SeventhLab</h1>
            <h1 className="home-slogan HeadingOne">Engineering the Cloud</h1>
            <span className="home-text02 Lead">
              <p>
                SeventhLab is <i>Engineering the Cloud</i>. We specialize in
                AWS-powered consulting, software development, and solutions
                architecture. Our team delivers tailored cloud solutions that
                enhance efficiency, scalability, and innovation.
              </p>
              <br />
              <p>
                Elevate your business with SeventhLab's cutting-edge Cloud
                Engineering services.
              </p>
            </span>
            {/* <div className="home-container02">
              <div className="home-container03">
                <PrimaryPinkButton button="contact us"></PrimaryPinkButton>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <img
        alt="Home first section background with multicolor waves"
        src="/img/home-heading-background.jpg"
        className="home-heading-background"
      />
      <section className="home-features">
        <FeatureCard
          title="Cloud Engineering"
          text="Bring the power of AWS to your business from the hands of our specialists."
          imageSrc="/img/cube.svg"
        ></FeatureCard>
        <FeatureCard
          title="+15 years in the Cloud"
          text="Take advantage of our experience building highly performant and robust solutions."
          imageSrc="/img/person.svg"
        ></FeatureCard>
        <FeatureCard
          title="Serverless First"
          text="Focus on delivering value to your customers, let the infrastructure heavy lifting for the experts."
          imageSrc="/img/rocket.svg"
        ></FeatureCard>
        <FeatureCard
          title="Costs Optimization"
          text="We know the Cloud, let us help you optimize your solutions keeping costs under control."
          imageSrc="/img/credit-card.svg"
        ></FeatureCard>
      </section>
      <Footer></Footer>
    </div>
  );
};

export { HomeView };
