import React from "react";

import "./feature-card.css";

interface FeatureCardProps {
  imageSrc?: string;
  imageAlt?: string;
  text?: string;
  title?: string;
}

const FeatureCard = (props: FeatureCardProps) => {
  const imageSrc = props.imageSrc ?? "/img/rocket.svg";
  const imageAlt = props.imageAlt ?? "image";
  const text =
    props.text ?? "Get the latest design ideas and turn it into reality.";
  const title = props.title ?? "Design";

  return (
    <div className="feature-card-container">
      <img alt={imageAlt} src={imageSrc} className="feature-card-image" />
      <h5 className="feature-card-text HeadingThree">{title}</h5>
      <span className="feature-card-text1">{text}</span>
    </div>
  );
};

export { FeatureCard };
